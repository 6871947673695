import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
    ChatContainer,
    ChatHeader,
    ChatList,
    ChatMessageContainer,
    MessageList,
    MessageInputContainer,
    MessageInput,
    SendButton,
    VoiceButton,
    FileButton,
    DoctorItem,
    DoctorAvatar,
    DoctorName,
    DoctorStatus,
    ChatBackground,
    SelectedDoctorInfo,
    PlaceholderContainer,
    StatusCircle,
    ChatTabsContainer,
    ChatTabButton,
    Tooltip,
    SectionContainer,
    MessageBubble,
    MessageTime,
    MessageStatusLabel,
    SearchInput,
    SearchContainer,
    ClearButton
} from './ChatStyle';
import {StyledModal} from "../../components/Modal";
import { FaPaperclip, FaMicrophone, FaArrowLeft, FaUserMd, FaUserInjured, FaPaperPlane, FaEllipsisV, FaTimes} from 'react-icons/fa';
import VitaMascot from '../../assets/Chat/img/Vita_6.png';
import { API_BASE_URL, API_BASE_URL_INSIGHT } from "../../utils/config";
import { useSede } from "../../context/SedeContext";
import { useWebSocket } from "../../context/WebSocketContext";
import { AuthContext } from "../../context/AuthContext";
import { toast } from 'react-toastify';

moment.locale('es'); // Configurar Moment.js en español

const Chat = () => {
    const { userData } = useContext(AuthContext);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isChatListVisible, setChatListVisible] = useState(true);
    const [activeTab, setActiveTab] = useState('doctors');
    const [medicos, setMedicos] = useState([]);
    const [whatsappSessions, setWhatsappSessions] = useState([]);
    const [mensajes, setMensajes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState(null);
    const { idSedeActual } = useSede();
    const { ws } = useWebSocket();
    const usuarioActualId = userData.id_usuario;

    // Función para agrupar los mensajes por fecha
    const agruparMensajesPorDia = (mensajes) => {
        return mensajes.reduce((grupos, mensaje) => {
            const fechaMensaje = moment(mensaje.created_at).startOf('day').format();
            if (!grupos[fechaMensaje]) {
                grupos[fechaMensaje] = [];
            }
            grupos[fechaMensaje].push(mensaje);
            return grupos;
        }, {});
    };

    // Función para formatear las fechas
    const formatearFecha = (fecha) => {
        const hoy = moment().startOf('day');
        const ayer = moment().subtract(1, 'days').startOf('day');

        if (moment(fecha).isSame(hoy, 'day')) {
            return 'Hoy';
        } else if (moment(fecha).isSame(ayer, 'day')) {
            return 'Ayer';
        } else {
            return moment(fecha).format('dddd, DD MMM YYYY');
        }
    };

    // Ordenar chats por el mensaje más reciente
    const sortChatsByRecentMessage = (chats) => {
        return chats.sort((a, b) => {
            const dateA = a.lastMessage ? new Date(a.lastMessage.created_at || a.lastMessage.ENVIADO_EN) : null;
            const dateB = b.lastMessage ? new Date(b.lastMessage.created_at || b.lastMessage.ENVIADO_EN) : null;

            if (dateA && dateB) {
                return dateB - dateA;
            } else if (dateA && !dateB) {
                return -1; // a tiene fecha, b no, a va primero
            } else if (!dateA && dateB) {
                return 1; // b tiene fecha, a no, b va primero
            } else {
                return 0; // ninguno tiene fecha, mantener orden original
            }
        });
    };

    // Actualizar el orden del chat
    const updateChatOrder = (updatedChat) => {
        if (activeTab === 'doctors') {
            const updatedMedicos = medicos.map(m => m.idUsuario === updatedChat.idUsuario ? updatedChat : m);
            setMedicos(sortChatsByRecentMessage(updatedMedicos));
        } else if (activeTab === 'patients') {
            const updatedSessions = whatsappSessions.map(s => s.id === updatedChat.id ? updatedChat : s);
            setWhatsappSessions(sortChatsByRecentMessage(updatedSessions));
        }
    };

    // Obtención de datos de médicos o pacientes según la pestaña activa
    useEffect(() => {
        if (idSedeActual && activeTab === 'doctors') {
            axios.get(`${API_BASE_URL}/chatMedicos/medicosPorSede/${idSedeActual}`, {
                params: { usuarioActualId }
            })
                .then(response => {
                    const medicosConectados = response.data.medicos.map(medico => ({
                        ...medico,
                        fullName: `${medico.nombre} ${medico.apellido}`,
                        avatar: medico.fotoPerfilUrl || '/logo.png',
                        status: 'Conectado',
                        unreadMessages: medico.unreadMessages,
                        lastMessage: medico.lastMessage
                    }));
                    setMedicos(sortChatsByRecentMessage(medicosConectados));
                })
                .catch(error => {
                    console.error('Error al obtener los médicos:', error);
                });
        } else if (activeTab === 'patients') {
            axios.get(`${API_BASE_URL_INSIGHT}/whatsapp/sessions`, {
                params: { idSede: idSedeActual }
            })
                .then(response => {
                    const sessions = response.data.map(session => ({
                        ...session,
                        id_cita: session.id_cita,
                        id_sede_cita: session.id_sede_cita,
                        lastMessage: session.messages?.length > 0 ? session.messages[session.messages.length - 1] : { created_at: session.updated_at }
                    }));
                    setWhatsappSessions(sortChatsByRecentMessage(sessions));
                })
                .catch(error => {
                    console.error('Error al obtener las sesiones de WhatsApp:', error);
                });
        }
    }, [idSedeActual, activeTab, usuarioActualId]);

    useEffect(() => {
        if (selectedChat) {
            if (activeTab === 'doctors') {
                axios.get(`${API_BASE_URL}/chatMedicos/mensaje/${usuarioActualId}/${selectedChat.idUsuario}`)
                    .then(response => {
                        setMensajes(response.data);
                    })
                    .catch(error => {
                        console.error('Error al obtener los mensajes:', error);
                        setMensajes([]);
                    });
            } else if (activeTab === 'patients') {
                setMensajes(selectedChat.messages || []);
            }
        }
    }, [selectedChat, usuarioActualId, activeTab]);

    useEffect(() => {
        if (ws && activeTab === 'doctors') {
            const handleMessage = (event) => {
                const message = JSON.parse(event.data);
                if (message.type === 'NEW_MESSAGE') {
                    const incomingMessage = message.mensaje;
                    if (selectedChat && incomingMessage.chatRoomId === selectedChat.idUsuario) {
                        setMensajes(prevMensajes => [...prevMensajes, incomingMessage]);
                    }
                    const updatedChat = medicos.find(m => m.idUsuario === incomingMessage.chatRoomId);
                    if (updatedChat) {
                        const updatedChatWithLastMessage = { ...updatedChat, lastMessage: incomingMessage };
                        updateChatOrder(updatedChatWithLastMessage);
                    }
                }
            };

            ws.addEventListener('message', handleMessage);

            return () => {
                ws.removeEventListener('message', handleMessage);
            };
        }
    }, [ws, selectedChat, activeTab, medicos]);

    const handleChatSelect = (chat) => {
        setSelectedChat(chat);
        if (window.innerWidth <= 768) {
            setChatListVisible(false);
        }
    };
    const handleSendMessage = async () => {
        if (mensaje.trim() && selectedChat) {
            if (activeTab === 'doctors') {
                const data = {
                    type: 'SEND_CHAT_MESSAGE',
                    usuarioId: usuarioActualId,
                    destinatarioId: selectedChat.idUsuario,
                    tipoMensaje: 'TEXTO',
                    contenido: mensaje,
                    urlArchivo: null
                };

                if (ws && ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify(data));
                } else {
                    console.error('WebSocket no está conectado.');
                }

                const newMessage = {
                    ...data,
                    ENVIADO_EN: new Date().toISOString(),
                    usuarioId: usuarioActualId
                };

                setMensajes(prevMensajes => [...prevMensajes, newMessage]);

                const updatedChat = { ...selectedChat, lastMessage: newMessage };
                setSelectedChat(updatedChat);
                updateChatOrder(updatedChat);

                setMensaje('');
            } else if (activeTab === 'patients') {
                try {
                    await axios.post(`${API_BASE_URL_INSIGHT}/whatsapp/messages`, {
                        sessionId: selectedChat.id,
                        phone: selectedChat.phone,
                        direction: 'sent',
                        messageBody: mensaje,
                        message_type: 'texto'
                    });

                    const newMessage = {
                        message_body: mensaje,
                        direction: 'sent',
                        created_at: new Date().toISOString()
                    };

                    setMensajes(prevMensajes => [
                        ...prevMensajes,
                        newMessage
                    ]);

                    const updatedChat = { ...selectedChat, lastMessage: newMessage };
                    setSelectedChat(updatedChat);
                    updateChatOrder(updatedChat);

                    setMensaje('');
                } catch (error) {
                    console.error('Error al enviar el mensaje:', error);
                }
            }
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && selectedChat && activeTab === 'doctors') {
            const formData = new FormData();
            formData.append('file', file);

            axios.post(`${API_BASE_URL}/chatMedicos/archivo/subir`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                const data = {
                    chatRoomName: `INDIVIDUAL_${[usuarioActualId, selectedChat.idUsuario].sort((a, b) => a - b).join('_')}`,
                    usuarioId: usuarioActualId,
                    destinatarioId: selectedChat.idUsuario,
                    tipoMensaje: 'ARCHIVO',
                    contenido: file.name,
                    urlArchivo: response.data.url
                };

                if (ws) {
                    ws.send(JSON.stringify({ type: 'SEND_CHAT_MESSAGE', ...data }));
                }

                setMensajes(prevMensajes => [...prevMensajes, { ...data, ENVIADO_EN: new Date().toISOString() }]);
                updateChatOrder(selectedChat);
            }).catch(error => {
                console.error('Error al subir el archivo:', error);
            });
        }
    };

    const resetSelectedChat = () => {
        setSelectedChat(null);
        setChatListVisible(true);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSelectedChat(null);
        setSearchTerm('');
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const clearSearch = () => {
        setSearchTerm('');
    };

    const openModal = (action) => {
        setModalAction(action);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalAction(null);
    };

    const filteredChats = activeTab === 'doctors'
        ? medicos.filter((medico) =>
            medico.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            medico.phone?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : whatsappSessions.filter((session) =>
            (session.nombre_contacto?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                session.phone.toLowerCase().includes(searchTerm.toLowerCase()))
        );

    const handleActionWithConfirmation = (actionType) => {
        setModalAction(actionType);
        setModalOpen(true);
    };

    const sendReminderMessage = async () => {
        if (selectedChat && selectedChat.id_cita && selectedChat.id_sede_cita) {
            const response = await axios.post(`${API_BASE_URL_INSIGHT}/reminders/whatsapp/sendMessageAppointment`, {
                messageType: 'recordatorio',
                idSede: selectedChat.id_sede_cita,
                idCita: selectedChat.id_cita
            });
            const { message } = response.data;
            setMensajes(prevMensajes => [
                ...prevMensajes,
                {
                    message_body: message || 'Mensaje de recordatorio enviado.',
                    direction: 'sent',
                    created_at: new Date().toISOString()
                }
            ]);
            toast.success('Mensaje de recordatorio enviado');
        } else {
            toast.error('No se pudo enviar el mensaje de recordatorio: no se encontró una próxima cita.');
        }
    };




    return (
        <ChatContainer>
            <StyledModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                width="400px"
                maxWidth="90%"
                height="auto"
                flexDirection="column"
                display="flex"
            >
                {modalAction === 'reminder' && (
                    <>
                        <h3>Confirmación de Recordatorio</h3>
                        <p>¿Está seguro de que quiere enviar el mensaje de recordatorio para la cita?</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <button
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    color: '#888',
                                    cursor: 'pointer',
                                    marginRight: '1rem'
                                }}
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                style={{
                                    backgroundColor: '#FF4D4D',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.5rem 1rem',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }}
                                onClick={async () => {
                                    await sendReminderMessage();
                                    closeModal();
                                }}
                            >
                                Enviar
                            </button>
                        </div>
                    </>
                )}
                {modalAction === 'anotherAction' && (
                    <>
                        <h3>Otra Acción</h3>
                        <p>¿Está seguro de que quiere realizar esta otra acción?</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <button
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    color: '#888',
                                    cursor: 'pointer',
                                    marginRight: '1rem'
                                }}
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                style={{
                                    backgroundColor: '#FF4D4D',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.5rem 1rem',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    // Lógica para otra acción
                                    closeModal();
                                }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </>
                )}
            </StyledModal>

            {(selectedChat || window.innerWidth > 768) && (
                <ChatHeader>
                    {selectedChat ? (
                        <SelectedDoctorInfo>
                            {window.innerWidth <= 768 && (
                                <FaArrowLeft
                                    onClick={resetSelectedChat}
                                    style={{ cursor: 'pointer', marginRight: '1rem', color: '#5b5b5b' }}
                                />
                            )}
                            <DoctorAvatar src={selectedChat.avatar || '/logo.png'} alt={selectedChat.fullName || selectedChat.phone} />
                            <div>
                                <DoctorName>{selectedChat.nombre_contacto || selectedChat.phone}</DoctorName>
                                <DoctorStatus>
                                    <StatusCircle status={selectedChat.status || 'Desconocido'} /> {selectedChat.status || 'Desconocido'}
                                </DoctorStatus>
                            </div>
                        </SelectedDoctorInfo>
                    ) : null}
                </ChatHeader>
            )}
            <div style={{ display: 'flex', height: '100%' }}>
                {isChatListVisible && (
                    <SectionContainer>
                        <ChatTabsContainer>
                            <ChatTabButton active={activeTab === 'doctors'} onClick={() => setActiveTab('doctors')}>
                                <FaUserMd style={{ marginRight: '0.5rem' }} /> Médicos
                            </ChatTabButton>
                            <ChatTabButton active={activeTab === 'patients'} onClick={() => setActiveTab('patients')}>
                                <FaUserInjured style={{ marginRight: '0.5rem' }} /> Pacientes
                            </ChatTabButton>
                        </ChatTabsContainer>
                        <SearchContainer>
                            <SearchInput
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Buscar por nombre o teléfono..."
                            />
                            {searchTerm && <ClearButton onClick={clearSearch}><FaTimes /></ClearButton>}
                        </SearchContainer>
                        <ChatList visible={isChatListVisible} style={{ maxHeight: '90vh', overflowY: 'auto' }}>
                            {filteredChats.map((chat, index) => (
                                <DoctorItem
                                    key={index}
                                    selected={selectedChat?.idUsuario === chat.idUsuario || selectedChat?.id === chat.id}
                                    onClick={() => setSelectedChat(chat)}
                                >
                                    <DoctorAvatar src={chat.avatar || '/logo.png'} alt={`Avatar ${index + 1}`} />
                                    <div>
                                        <Tooltip title={chat.fullName || chat.nombre_contacto || chat.phone}>
                                            <DoctorName>
                                                {chat.fullName || chat.nombre_contacto || chat.phone}
                                            </DoctorName>
                                        </Tooltip>
                                    </div>
                                </DoctorItem>
                            ))}
                        </ChatList>
                    </SectionContainer>
                )}
                <ChatMessageContainer>
                    {selectedChat ? (
                        <ChatBackground>
                            <MessageList>
                                {Object.entries(agruparMensajesPorDia(mensajes)).map(([fecha, mensajesDelDia], idx) => (
                                    <div key={idx}>
                                        <div style={{ textAlign: 'center', margin: '10px 0', color: '#888' }}>
                                            {formatearFecha(fecha)}
                                        </div>
                                        {mensajesDelDia.map((msg, idx) => (
                                            <div key={idx}>
                                                <MessageBubble isOwnMessage={msg.direction === 'sent'}>
                                                    <div>{msg.message_body}</div>
                                                    <MessageTime isOwnMessage={msg.direction === 'sent'}>
                                                        {moment(msg.created_at).format('HH:mm')}
                                                    </MessageTime>
                                                </MessageBubble>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </MessageList>
                            <MessageInputContainer>
                                <MessageInput
                                    value={mensaje}
                                    onChange={(e) => setMensaje(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && mensaje.trim() !== '') {
                                            handleSendMessage();
                                            e.preventDefault();
                                        }
                                    }}
                                    placeholder="Escribe un mensaje..."
                                />
                                <SendButton onClick={handleSendMessage}>
                                    <FaPaperPlane />
                                </SendButton>
                                <FileButton as="label">
                                    <FaPaperclip />
                                    <input type="file" style={{ display: 'none' }} onChange={handleFileUpload} />
                                </FileButton>
                                <VoiceButton>
                                    <FaMicrophone />
                                </VoiceButton>
                                <button onClick={toggleMenu} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                    <FaEllipsisV />
                                </button>
                                {isMenuOpen && (
                                    <div style={{
                                        position: 'absolute',
                                        bottom: '60px',
                                        right: '10px',
                                        background: '#fff',
                                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        zIndex: 100
                                    }}>
                                        <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                            <li
                                                onClick={() => {
                                                    setMenuOpen(false);
                                                    handleActionWithConfirmation('reminder');
                                                }}
                                                style={{padding: '10px', cursor: 'pointer'}}
                                            >
                                                Mensaje de recordatorio
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </MessageInputContainer>
                        </ChatBackground>
                    ) : (
                        <PlaceholderContainer>
                            <h2>Con Vitalink administra mejor tu tiempo y tu información</h2>
                            <p>Selecciona un {activeTab === 'doctors' ? 'médico' : 'paciente'} para empezar a chatear.</p>
                            <img src={VitaMascot} alt="Vita Mascot" />
                        </PlaceholderContainer>
                    )}
                </ChatMessageContainer>
            </div>
        </ChatContainer>
    );
};

export default Chat;