import styled, { keyframes } from 'styled-components';

export const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100vw;
    background-color: #f7f9fc;

    @media (max-width: 768px) {
        flex: 1;
        width: 100%;
        overflow-x: hidden;
    }
`;

export const ChatHeader = styled.div`
    padding: 1rem;
    background-color: var(--gris);
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 80px;

    @media (max-width: 768px) {
        font-size: 1rem;
        height: 60px;
        padding: 0.5rem;
        justify-content: space-between;
    }
`;

export const SelectedDoctorInfo = styled.div`
    display: flex;
    align-items: center;
    animation: ${keyframes`
        0% {
            transform: translateY(20%);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    `} 0.3s ease-out forwards;

    @media (max-width: 768px) {
        flex-direction: row;
    }
`;

export const SectionContainer = styled.div`
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const ChatList = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #ffffff;
    border-right: 1px solid #e0e0e0;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: fixed;
        left: ${props => (props.visible ? '0' : '-100%')};
        transition: left 0.3s ease-in-out;
    }
`;

export const ChatMessageContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
    position: relative;
    height: 100%;
    min-height: 0;

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
`;

export const MessageList = styled.div`
    flex: 1 1 auto;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;

    @media (max-width: 768px) {
        padding: 0.5rem;
    }
`;


export const ChatBackground = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const MessageInputContainer = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-top: 1px solid #e0e0e0;
    width: 97%;
    flex-shrink: 0;

    @media (max-width: 768px) {
        padding: 0.5rem;
        flex-direction: row;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
`;

export const MessageInput = styled.input`
    flex: 1;
    padding: 0.5rem;
    border-radius: 20px;
    border: 1px solid #e0e0e0;
    margin-right: 1rem;

    @media (max-width: 768px) {
        margin-right: 0.5rem;
    }
`;

export const SendButton = styled.button`
    background-color: #FF4D4D;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
        background-color: #FF3333;
    }

    @media (max-width: 768px) {
        padding: 0.5rem;
        border-radius: 10px;
    }
`;

export const VoiceButton = styled.button`
    background-color: #006CFF;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 50%;
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover {
        background-color: #0051CC;
    }

    @media (max-width: 768px) {
        margin-left: 0;
        padding: 0.5rem;
        border-radius: 10px;
    }
`;

export const FileButton = styled.button`
    background-color: #FFA500;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 50%;
    margin-left: 0.5rem;
    cursor: pointer;

    &:hover {
        background-color: #FF8C00;
    }

    @media (max-width: 768px) {
        margin-left: 0;
        padding: 0.5rem;
        border-radius: 10px;
    }
`;

export const PlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #333;
    text-align: center;
    padding: 2rem;

    @media (max-width: 768px) {
        display: none;
    }

    h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        color: #006CFF;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }

    p {
        font-size: 1.2rem;
        color: #666;

        @media (max-width: 768px) {
            font-size: 1rem;
        }
    }

    img {
        margin-top: 1rem;
        width: 50%;
    }
`;

export const DoctorItem = styled.div`
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #f2f2f2;
    }

    @media (max-width: 768px) {
        padding: 0.5rem;
    }
`;

export const DoctorAvatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;

    @media (max-width: 768px) {
        width: 30px;
        height: 30px;
        margin-right: 0.5rem;
    }
`;

export const DoctorName = styled.span`
    font-weight: bold;
    color: #333;
    display: block;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

export const DoctorStatus = styled.span`
    font-size: 0.9rem;
    color: #888;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        font-size: 0.8rem;
    }
`;

export const StatusCircle = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 0.5rem;
    background-color: ${({ status }) => {
        switch (status) {
            case 'Conectado':
                return 'green';
            case 'Desconectado':
                return 'red';
            case 'Suspendido':
                return 'yellow';
            default:
                return 'gray';
        }
    }};
`;

export const ChatTabsContainer = styled.div`
    display: flex;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    width: 100%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const ChatTabButton = styled.button`
    flex: 1;
    padding: 1rem;
    background-color: ${(props) => (props.active ? '#007bff' : '#fff')};
    color: ${(props) => (props.active ? '#fff' : '#000')};
    border: none;
    border-bottom: ${(props) => (props.active ? '2px solid #0056b3' : '2px solid transparent')};
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;

    @media (max-width: 768px) {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0.5rem;
    }
`;

export const Tooltip = styled.div`
    position: relative;
    display: inline-block;

    &:hover::after {
        content: attr(title);
        position: absolute;
        left: 50%;
        bottom: 125%;
        transform: translateX(-50%);
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 1;
        opacity: 1;
    }
`;

export const MessageBubble = styled.div`
    display: inline-block;
    padding: 0.5rem 0.8rem;
    border-radius: 10px;
    max-width: 75%;
    margin-bottom: 0.2rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
    word-wrap: break-word;
    overflow-wrap: break-word;
    user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    
    &:after {
        border-width: 6px;
        ${({ isOwnMessage }) => (isOwnMessage ? 'right: -6px;' : 'left: -6px;')}
        ${({ isOwnMessage }) =>
                isOwnMessage
                        ? 'border-left-color: #007bff;'
                        : 'border-right-color: #e0e0e0;'}
    }
`;
export const MessageTime = styled.span`
    display: block;
    font-size: 0.7rem;
    color: ${({ isOwnMessage }) => (isOwnMessage ? '#ddd' : '#666')};
    margin-top: 0.2rem;
    text-align: ${({ isOwnMessage }) => (isOwnMessage ? 'right' : 'left')};
`;


export const MessageStatusLabel = styled.div`
    font-size: 0.85rem;
    font-weight: bold;
    color: ${({ unread }) => (unread ? '#FF4D4D' : '#888')};
    background-color: ${({ unread }) => (unread ? '#FFE5E5' : '#f5f5f5')};
    padding: 2px 8px;
    border-radius: 12px;
    margin-top: 5px;
    display: inline-block;
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
`;

export const SearchInput = styled.input`
    flex: 1;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    transition: background-color 0.3s;

    &:focus {
        background-color: #e9ecef;
    }
`;


export const ClearButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #888;
    position: absolute;
    right: 10px;
    transition: color 0.3s;

    &:hover {
        color: #333;
    }
`;